'use client';
import React from 'react';
import { LinkExternalButtonProps } from './link-external-button.interfaces';
import { linkButtonVariants } from './link-external-button.variants';
import { cn } from '@/utils';

export const LinkExternalButton: React.FC<LinkExternalButtonProps> = ({
  variant,
  className,
  href,
  target,
  ...props
}) => {
  return <a className={cn(linkButtonVariants({ variant }), className)} href={href} target={target} {...props} />;
};
