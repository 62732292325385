'use client';
import React, { useEffect, useState } from 'react';

import { LinkExternalButton } from '@/components/ui/link-external-button';

export const Banner: React.FC = () => {
  const [academiasCounter, setAcademiasCounter] = useState(0);
  const [modalidadesCounter, setModalidadesCounter] = useState(0);

  const academiasLimit = 23;
  const modalidadesLimit = 250;

  const startCounting = (counter: number, setCounter: React.Dispatch<React.SetStateAction<number>>, limit: number) => {
    const interval = setInterval(() => {
      setCounter((counter) => {
        if (counter < limit) {
          return counter + 1;
        }
        clearInterval(interval);
        return counter;
      });
    }, 130);
  };

  useEffect(() => {
    startCounting(academiasCounter, setAcademiasCounter, academiasLimit);
    startCounting(modalidadesCounter, setModalidadesCounter, modalidadesLimit);
  }, [academiasCounter, modalidadesCounter]);

  return (
    <section className="bg-primary-800 relative flex items-end overflow-hidden lg:items-center">
      <div
        data-poster-url="https://cms.totalpass.com/uploads/banner_campanha_fbc3cf2825.png"
        data-video-urls="https://cms.totalpass.com/uploads/banner_campanha_2025_dd35e18484.webm, https://cms.totalpass.com/uploads/banner_campanha_2025_65809b4ad3.mp4"
        data-autoplay
        data-loop
        data-wf-ignore
        className="absolute z-10 flex min-h-full w-full items-center justify-center"
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          data-wf-ignore
          className="absolute z-10 min-h-full w-auto min-w-full max-w-none object-cover"
          style={{ zoom: '40%' }}
        >
          <source src="https://cms.totalpass.com/uploads/banner_campanha_2025_dd35e18484.webm" type="video/webm" />
          <source src="https://cms.totalpass.com/uploads/banner_campanha_2025_65809b4ad3.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="container mx-auto px-8 text-white">
        <div className="relative z-10 flex flex-col gap-10 pb-12 pt-80 md:items-center lg:w-[50%] lg:items-start lg:pb-28">
          <h1 className="text-2xl font-medium md:text-center lg:text-left lg:text-5xl">
            Faz total sentido pedir TotalPass para o seu RH e
            <span className="text-primary-40"> ganhar 1 ano do nosso melhor plano</span>
          </h1>
          {/* <div className="flex flex-col gap-4">
            <p className="text-base lg:text-lg">Faça parte e tenha acesso a mais de:</p>
            <div className="flex gap-6 lg:gap-12">
              <div className="flex flex-col">
                <p className="text-[32px] font-bold leading-none lg:text-[40px]">{academiasCounter} mil</p>
                <p className="text-sm lg:text-base">academias</p>
              </div>
              <div className="flex flex-col">
                <p className="text-[32px] font-bold leading-none lg:text-[40px]">{modalidadesCounter}</p>
                <p className="text-sm lg:text-base">modalidades</p>
              </div>
            </div>
          </div> */}
          <div className="flex h-full w-full flex-col gap-4 md:w-[350px] lg:flex-row">
            {/* <LinkButton className="rounded-full text-base font-normal" href="/br/corporativo/" variant="primary">
              Para empresas
            </LinkButton>
            <LinkButton className="rounded-full text-base font-normal" href="#plans" variant="primary">
              Para colaboradores
            </LinkButton> */}
            <LinkExternalButton
              className="rounded-full text-base font-normal md:w-[350px]"
              href="https://totalpass.com/faztotalsentido?utm_source=site&utm_medium=referral&utm_campaign=br_cont_site_referral_totalpass_oportunidade_aberto_participacao_faz-total-sentido-2025_saz_recursos-humanos_20250315__&utm_term=___&utm_content=home-do-site_saiba-mais_texto_"
              target="_blank"
              variant="primary"
            >
              Saiba mais
            </LinkExternalButton>
          </div>
        </div>
      </div>
    </section>
  );
};
